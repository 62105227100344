<template>
  <b-col
    cols="12"
    xl="9"
    md="8"
  >
    <b-card
      no-body
      class="invoice-preview-card"
    >
      <!-- Header -->
      <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">
          <!-- Header: Left Content -->
          <div>
            <div class="logo-wrapper mb-0">
              <!-- User Avatar -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="result.holder ? result.holder.image : ''"
                  :text="result.holder.name != null ? result.holder.name.charAt(0) : '-'"
                  :variant="`light-danger`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4
                      v-if="result.holder"
                      class="mb-0"
                    >
                      {{ result.holder.name != null ? result.holder.name : '-' }}
                    </h4>
                    <span class="card-text text-break text-capitalize">Status Talent: {{ result.holder.status }}</span>
                    <div class="align-items-center">
                      <!-- Pital Pay -->
                      <div
                        v-for="(wallet, index) in result.holder.wallets"
                        :key="`wallet${index}`"
                        class="d-flex align-items-center mr-2 mt-1"
                      >
                        <b-avatar
                          variant="light-success"
                          rounded
                        >
                          <feather-icon
                            icon="DollarSignIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0 text-capitalize">
                            Pital {{ wallet.type }}
                          </h5>
                          <small>
                            Balance: <strong>{{ wallet.balance | formatAmount }}</strong>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
      <!-- Spacer -->
      <hr class="invoice-spacing">
      <!-- Invoice Description: Total -->
      <b-card-body class="invoice-padding pt-0">
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
          >
            <div>
              <table class="table-responsive">
                <tbody>
                  <tr>
                    <td class="pr-1">
                      Name
                    </td>
                    <td>{{ result.holder.name ? result.holder.name : '-' }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Payment Method
                    </td>
                    <td>{{ result.payment_method | humanize }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Akun Pembayaran
                    </td>
                    <td class="d-flex align-items-center">
                      <div v-if="result.payment_account !== null">
                        <div class="mr-2">
                          <b-img
                            :src="result.payment_account.logo"
                            width="70"
                          />
                        </div>
                        <span>
                          Nama Bank: {{ result.payment_account.bank_name }}<br>
                          Nomor Rekening: {{ result.payment_account.account_number }}<br>
                          Atas Nama: {{ result.payment_account.account_name }}<br>
                        </span>
                      </div>
                      <span v-else>-</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Status Topup:
                    </td>
                    <td>
                      <span
                        class="badge text-capitalize badge-pill"
                        :class="
                          result.status == 'success'
                            ? 'badge-light-success'
                            : result.status == 'pending' ? 'badge-light-warning' : 'badge-light-danger'
                        "
                      >{{ result.status }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Time
                    </td>
                    <td>{{ result.time }}</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Expired Time
                    </td>
                    <td>{{ result.expire_time }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <!-- Col: Total -->
          <b-col
            cols="12"
            md="6"
            lg="6"
            class="mt-md-6 d-lg-flex justify-content-end"
            order="1"
            order-md="2"
          >
            <div class="invoice-total-wrapper">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Amount:
                </p>
                <p class="invoice-total-amount">
                  {{ result.amount | formatAmount }}
                </p>
              </div>
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Admin Fee:
                </p>
                <p class="invoice-total-amount">
                  {{ result.admin_fee | formatAmount }}
                </p>
              </div>
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Unique Code:
                </p>
                <p class="invoice-total-amount">
                  {{ result.unique_code }}
                </p>
              </div>
              <hr class="my-50">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  {{ result.total | formatAmount }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BAvatar,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BImg,
  },
  props: {
    result: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
